import { createContext, useEffect, useState, useRef, useCallback } from "react";
import PouchDB from "pouchdb-browser";
import axios from "axios";

import useBreakpoint from "../hooks/use-breakpoints";

const INITIAL_STATE = {
  ATTRACTOR: {},
  AREAS: [],
  MAP: {},
};

const DataContext = createContext({
  attractor: INITIAL_STATE.ATTRACTOR,
  areas: INITIAL_STATE.AREAS,
  map: INITIAL_STATE.MAP,
});

export { DataContext };

const getCmsUrl = () =>
  `https://api.storyblok.com/v1/cdn/stories?version=published&token=${process.env.REACT_APP_PUBLIC_STORYBOOK}`;

export default function DataProvider({ children }) {
  const bp = useBreakpoint();

  const [videoErrorMsg, setVideoErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [attractor, setAttractor] = useState(INITIAL_STATE.ATTRACTOR);
  const [areas, setAreas] = useState(INITIAL_STATE.AREAS);
  const [map, setMap] = useState(INITIAL_STATE.MAP);
  const [rows, setRows] = useState([]);
  const db = useRef(null);

  const setUpdatedData = useCallback(
    async (response) => {
      const content = response.data.stories[0].content;
      setVideoErrorMsg(content.video_error);
      setMap({ title: content.map[0].title, img: content.map[0][`img_${bp}`] });
    },
    [bp]
  );

  const formatAreas = useCallback(
    async (response) => {
      const content = response.data.stories[0].content;
      let allDocs = await db.current.allDocs(
        { include_docs: true, descending: true },
        function (err, doc) {
          return doc.rows;
        }
      );

      // handle attractor video first
      const attractorVideo = content.attractor[0][`video_${bp}`];
      const isAttractorDownloaded = allDocs?.rows?.find(
        (r) => r.id === `video_${attractorVideo.id}`
      );

      // check if attractor video is in use
      let rows = allDocs.rows.map((r) => ({
        ...r,
        inUse: r.id === `video_${attractorVideo.id}` ? true : false,
      }));

      setAttractor({
        button_copy: content.attractor[0].button_copy,
        description: content.attractor[0].description,
        title: content.attractor[0].title,
        video: {
          ...attractorVideo,
          downloaded: !!isAttractorDownloaded,
          downloading: false,
          doc: isAttractorDownloaded
            ? {
                _rev: isAttractorDownloaded.doc._rev,
                _id: isAttractorDownloaded.doc._id,
              }
            : {},
        },
      });

      const areas = content.areas.map((a) => {
        const { _uid, name, videos, more_title, more_button, still_image_PC } =
          a;

        const formattedVideos = videos.map((v) => {
          const { title, description, _uid } = v;
          const video = v[`video_${bp}`];
          const poster = v[`poster_${bp}`];
          const { id } = video;

          const isDownloaded = allDocs?.rows?.find(
            (r) => r.id === `video_${id}`
          );
          if (isDownloaded) {
            rows = rows.map((r) =>
              r.id === `video_${id}` ? { ...r, inUse: true } : r
            );
          }

          return {
            title,
            description,
            poster: poster,
            video: video,
            _uid,
            downloaded: !!isDownloaded,
            downloading: false,
            doc: isDownloaded
              ? { _rev: isDownloaded.doc._rev, _id: isDownloaded.doc._id }
              : {},
          };
        });

        return {
          _uid,
          name,
          more_title,
          more_button,
          videos: formattedVideos,
          still_image: still_image_PC?.filename,
        };
      });

      setRows(rows.filter((r) => !r.inUse));
      setAreas(areas);
      setIsLoading(false);
    },
    [bp]
  );

  const handleResponseFromCms = useCallback(
    (response) => {
      setUpdatedData(response);
      formatAreas(response);
    },
    [formatAreas, setUpdatedData]
  );

  const reloadDataFromCms = useCallback(() => {
    setIsLoading(true);
    setError("");
    axios
      .get(`${getCmsUrl()}&timestamp=${Date.now()}&reload=true`)
      .then((response) => {
        handleResponseFromCms(response);
      })
      .catch(() => {
        setIsLoading(false);
        setError(`Failed to reload: network error`);
      });
  }, [handleResponseFromCms]);

  const setVideoAsDownloading = useCallback(
    (vid, auid = null, isLoading = true, isDownloaded = false, doc = {}) => {
      if (auid) {
        setAreas((areas) =>
          areas.map((a) => ({
            ...a,
            videos: a.videos.map((v) =>
              v.video.id === vid
                ? {
                    ...v,
                    downloading: isLoading,
                    downloaded: isDownloaded,
                    doc,
                  }
                : v
            ),
          }))
        );
      } else {
        // is attractor video
        setAttractor((a) => ({
          ...a,
          video: {
            ...a.video,
            downloading: isLoading,
            downloaded: isDownloaded,
            doc,
          },
        }));
      }
    },
    []
  );

  const setVideoAsDeleted = useCallback((vid) => {
    setAreas((areas) =>
      areas.map((a) => ({
        ...a,
        videos: a.videos.map((v) =>
          v.video.id == vid ? { ...v, downloaded: false } : v
        ),
      }))
    );
  }, []);

  const downloadVideo = useCallback(
    (v, a = {}) => {
      const { title, video, poster } = v;
      const { filename, id } = video;
      const { filename: posterimg } = poster || {};
      setVideoAsDownloading(id, a._uid, true);

      const request = new Request(filename);

      fetch(request)
        .then((response) => response.blob())
        .then(function (myBlob) {
          const videoRow = {
            _id: "video_" + id,
            title: title,
            _attachments: {
              "video.mp4": {
                content_type: "video/mp4",
                data: myBlob,
              },
            },
          };
          db.current
            .put(videoRow)
            .then(function (result) {
              if (posterimg) {
                const posterReq = new Request(posterimg);
                fetch(posterReq, {
                  mode: "no-cors", // no-cors, *cors, same-origin
                })
                  .then(() => {
                    console.log("POSTER IMG DOWNLOADED AND CACHED");
                    // video stored in DB
                    setVideoAsDownloading(id, a._uid, false, true, {
                      _rev: result.rev,
                      _id: result.id,
                    });
                  })
                  .catch(() => {
                    throw new Error("IMAGE FAILED TO DOWNLOAD");
                  });
              } else {
                // video stored in DB
                setVideoAsDownloading(id, a._uid, false, true, {
                  _rev: result.rev,
                  _id: result.id,
                });
              }
            })
            .catch(function (err) {
              setVideoAsDownloading(id, a._uid, false, false);
              setError(`Failed to download: network error`);
              console.log(err);
            });
        })
        .catch(() => {
          setVideoAsDownloading(id, a._uid, false, false);
          setError(`Failed to download: network error`);
        });
    },
    [setVideoAsDownloading]
  );

  const deleteVideo = useCallback(
    (v, a) => {
      db.current.remove(v._id, v._rev).then((r) => {
        if (a) {
          // video exist in cms
          const vId = v._id.split("video_")[1];
          setVideoAsDeleted(vId);
        } else {
          // if attractor, then update ui
          if (attractor?.video?.doc?._id === v._id) {
            setAttractor({
              ...attractor,
              video: { ...attractor.video, downloaded: false },
            });
          } else {
            // old video, just remove from UI once deleted
            setRows((rows) => rows.filter((r) => r.id !== v._id));
          }
        }
      });
    },
    [attractor]
  );

  useEffect(() => {
    db.current = new PouchDB("videos");
    axios
      .get(`${getCmsUrl()}`)
      .then((response) => {
        handleResponseFromCms(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [handleResponseFromCms]);

  const getVideoSrcFromDb = async (id) => {
    const doc = await db.current.getAttachment("video_" + id, "video.mp4");

    const url = window.URL || window.webkitURL;
    return url.createObjectURL(doc);
  };

  return (
    <DataContext.Provider
      value={{
        error,
        attractor,
        areas,
        map,
        reloadDataFromCms,
        downloadVideo,
        isLoading,
        getVideoSrcFromDb,
        rows,
        deleteVideo,
        videoErrorMsg,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
