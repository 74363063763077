import { useState, useEffect } from "react";
import throttle from "lodash.throttle";

import { breakpoints } from "../constants/breakpoints";

const getDeviceConfig = (width) => {
  return width > breakpoints.pc ? "pc" : "ipad";
};

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() =>
    getDeviceConfig(window.innerWidth)
  );

  useEffect(() => {
    const calcInnerWidth = throttle(function () {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener("resize", calcInnerWidth);
    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);

  return brkPnt;
};
export default useBreakpoint;
