import React from "react";
import styled from "styled-components";
import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.es";

import px from "../../../helpers/px";
import device from "../../../constants/breakpoints";

const resolver = new RichTextResolver();

const Wrapper = styled.div`
  padding: 0 ${px(22)} ${px(5)};
`;

const H1 = styled.h1`
  font-size: ${px(24)};
  line-height: ${px(29)};

  @media ${device.pc} {
    font-size: ${px(30)};
    line-height: ${px(36)};
  }
`;

const RichText = styled.div`
  font-size: ${px(18)};
  line-height: ${px(22)};
  margin-top: 1em;
  margin-bottom: 1em;
  font-family: "RRLight", sans-serif;
  letter-spacing: 0.09em;
`;

function Copy({ title, body }) {
  const html = resolver.render(body);

  return (
    <Wrapper>
      <H1>{title}</H1>
      <RichText dangerouslySetInnerHTML={{ __html: html }}></RichText>
    </Wrapper>
  );
}

export default Copy;
