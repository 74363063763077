import styled from "styled-components";
import device from "../constants/breakpoints";

import px from "../helpers/px";

export const MARGIN_HORIZONTAL_IPAD = 67;
export const MARGIN_HORIZONTAL_PC = 140;

export const Wrapper = styled.div`
  background: ${(props) =>
    props.bg
      ? `#fff url('${props.bg}') no-repeat center center / cover`
      : "#fff"};

  flex: 1;
  color: #000;

  display: flex;
  flex-direction: column;
`;

export const Inner = styled.div`
  flex: 1;
  padding: 0 ${px(MARGIN_HORIZONTAL_IPAD)};
  ${(props) => (props.position ? `position: ${props.position};` : "")}

  @media ${device.pc} {
    padding: 0 ${px(MARGIN_HORIZONTAL_PC)};
  }
`;
