import { ROUTES } from "../contexts/router";
import Landing from "../screens/Landing";
import Admin from "../screens/Admin";
import Map from "../screens/Map";
import Area from "../screens/Area";

export const ROUTES_CONFIG = {
  [ROUTES.LANDING]: {
    header: {
      isLight: true,
    },
    Component: Landing,
  },
  [ROUTES.AREA]: {
    header: {},
    Component: Area,
  },
  [ROUTES.ADMIN]: {
    header: {},
    Component: Admin,
  },
  [ROUTES.MAP]: {
    header: {
      isLight: false,
    },
    Component: Map,
  },
};
