import React from "react";
import styled from "styled-components";

const IMAGE_RATIO = `${(1041 / 1880) * 100}%`;

const Container = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: ${IMAGE_RATIO};
  position: relative;
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

function RatioContainer({ children }) {
  return (
    <Container>
      <Inner>{children}</Inner>
    </Container>
  );
}

export default RatioContainer;
