import { createContext, useState, useEffect, useCallback } from "react";
import useLocalStorage from "../hooks/use-local-storage";

export const ROUTES = {
  LANDING: "landing",
  AREA: "area",
  ADMIN: "admin",
  MAP: "map",
};

const INITIAL_STATE = {
  DEFAULT_AREA: null,
  SELECTED_AREA: null,
  ROUTE: ROUTES.ADMIN,
};

const RouterContext = createContext({
  selectedArea: INITIAL_STATE.SELECTED_AREA,
  route: INITIAL_STATE.ROUTE,
});

export { RouterContext };

export default function RouterProvider({ children }) {
  const [storedRoute, setStoredRoute] = useLocalStorage(
    "route",
    INITIAL_STATE.ROUTE
  );
  const [storedArea, setStoredArea] = useLocalStorage(
    "area",
    INITIAL_STATE.SELECTED_AREA
  );

  const [defaultArea, setDefaultArea] = useLocalStorage(
    "default_area",
    INITIAL_STATE.DEFAULT_AREA
  );

  const [route, setRoute] = useState(storedRoute);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);

  const onSetRoute = useCallback(
    (r) => {
      setRoute(r);
    },
    [setRoute]
  );

  const onSetArea = useCallback(
    (a) => {
      setStoredArea(a);
      onSetRoute(ROUTES.AREA);
    },
    [setStoredArea, onSetRoute]
  );

  const onSetDefaultArea = useCallback(
    (a) => {
      setDefaultArea(a);
    },
    [setDefaultArea]
  );

  const setVideoPlaying = useCallback((isPlaying) => {
    setVideoIsPlaying(isPlaying);
  }, []);

  useEffect(() => {
    setStoredRoute(route);
  }, [route, setStoredRoute]);

  return (
    <RouterContext.Provider
      value={{
        route,
        onSetArea,
        onSetRoute,
        area: storedArea,
        setVideoPlaying,
        videoIsPlaying,
        onSetDefaultArea,
        setStoredArea,
        defaultArea,
      }}
    >
      {children}
    </RouterContext.Provider>
  );
}
