import React from "react";
import styled from "styled-components";

const Svg = styled.svg`
  fill: currentColor;
`;

const Logo = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 392.3 392.3">
    <path d="M206.5 353.5h69.9c-10.7-5.7-14.5-20.8-15.1-35.3-6.9-65.5-43.4-116.5-52.3-129.7 41.5-24.9 59.8-63 59.8-98.2 0-58.6-51.6-86.3-96.3-86.3H54.7c4.4 6.3 7.6 8.8 7.6 23.3v298.5c0 14.5-6.3 21.4-10.7 27.7h53.5c-4.4-6.3-10.7-13.2-10.7-27.7V216.2c36.1-.6 66.2-6.5 90.4-16.1 36.8 39.5 41.2 96.8 41.9 112.5.5 15.7-7.6 29.6-20.2 40.9zM94.4 189.2V24.8h69.3c47.9 0 63.6 30.2 63 62.3-3.2 88.8-91.4 102.1-132.3 102.1z" />
    <path d="M157.3 250.9c16.6-.3 32.5-1.6 46.6-4-1.9-6.4-8.8-20.2-12.3-26-12.9 2.2-24.8 2.9-34.3 2.9v-6.9c-4.4 1.6-23.9 4.4-32.1 5.4v138.2c0 14.5-6.3 21.4-10.7 27.7H168c-4.4-6.3-10.7-13.2-10.7-27.7V250.9zM214 58.5h-56.7v112.7c-7.2 2.5-21.7 7.2-32.1 8.2V61c0-14.5-3.1-17-7.6-23.3H196c6.4 3.8 14.9 12.9 18 20.8zm63.2 20.1c9.6 11.5 13.3 26.9 12.9 42.8-1.7 48.1-28.4 74-58.9 87.7 4.5 6.8 10.1 19.6 13.6 26.6.9-.3 3.6-1.3 4.4-1.6 36.8 39.5 40.3 97.1 40.9 112.8.6 15.7-7.6 29.6-20.1 40.9h69.9c-10.7-5.7-14.5-20.8-15.1-35.3-6.9-65.5-43.4-116.5-52.3-129.7 41.5-24.9 59.8-63 59.8-98.2 0-46.2-33.1-73.5-68.6-82.8 2.9 4.6 10.7 20.1 13.5 36.8z" />
  </Svg>
);

export default Logo;
