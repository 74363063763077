import React, { useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";

import px from "../../helpers/px";
import { RouterContext, ROUTES } from "../../contexts/router";
import { DataContext } from "../../contexts/data";
import Arrow from "./Arrow";
import device from "../../constants/breakpoints";
import wordmark from "./wordmark.svg";

const Wrapper = styled.div`
  background: #000;
  flex: 1;
  color: #fff;
  overflow: hidden;

  position: relative;
`;

const Inner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${px(450)};
  text-align: center;

  @media ${device.pc} {
    width: ${px(600)};
  }
`;

const H1 = styled.h1`
  font-size: ${px(54)};
  line-height: ${px(53)};
  margin-bottom: ${px(17)};

  @media ${device.pc} {
    font-size: ${px(75)};
    line-height: ${px(73)};
    margin-bottom: ${px(22)};
  }
`;

const H2 = styled.h2`
  font-size: ${px(34)};
  line-height: ${px(52)};
  font-family: "RRLight", sans-serif;
  margin-bottom: ${px(31)};

  @media ${device.pc} {
    font-size: ${px(48)};
    line-height: ${px(73)};
    margin-bottom: ${px(48)};
  }
`;

const Cta = styled.button`
  background-color: #57208c;
  color: #fff;
  border: none;
  padding: ${px(14)} ${px(28)};
  display: flex;
  align-items: center;
  margin: auto;
  border-radius: ${px(50)};
  font-family: "RRRegular", sans-serif;
  letter-spacing: 0.0165em;

  @media ${device.pc} {
    font-size: ${px(18)};
    line-height: ${px(22)};
    padding: ${px(15)} ${px(38)};
  }
`;

const IconWrapper = styled.div`
  width: ${px(12)};
  margin-left: ${px(24)};
`;

const Img = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: ${px(128)};
  bottom: ${px(50)};

  @media ${device.pc} {
    width: ${px(201)};
    bottom: ${px(66)};
  }
`;

const Video = styled.video`
  pointer-events: none;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

function Landing({ children }) {
  const [src, setSrc] = useState("");
  const { onSetRoute, area, defaultArea, setStoredArea } =
    useContext(RouterContext);
  const { attractor, getVideoSrcFromDb } = useContext(DataContext);
  const videoRef = useRef();

  useEffect(() => {
    // if there is a default area selected
    // then we override the most recent selection
    // so when attractor breaks, we return to the default one
    if (defaultArea) {
      setStoredArea(defaultArea);
    }
  }, []);

  useEffect(() => {
    if (attractor.video) {
      async function fetchVideoUrl() {
        const { id, filename } = attractor?.video;
        let href;
        try {
          href = await getVideoSrcFromDb(id);
        } catch (e) {
          if (navigator.onLine) {
            href = filename;
          }
        }

        setSrc(href);
        videoRef.current.load();
      }
      fetchVideoUrl();
    }
  }, [getVideoSrcFromDb, attractor]);

  return (
    <Wrapper>
      <Video autoPlay="autoplay" loop="loop" muted ref={videoRef}>
        <source src={src} type="video/mp4" />
      </Video>
      {children}
      <Inner>
        <H1>{attractor.title}</H1>
        <H2>{attractor.description}</H2>
        <Cta onClick={() => onSetRoute(area ? ROUTES.AREA : ROUTES.MAP)}>
          {attractor.button_copy}
          <IconWrapper>
            <Arrow />
          </IconWrapper>
        </Cta>
      </Inner>
      <Img src={wordmark} />
    </Wrapper>
  );
}

export default Landing;
