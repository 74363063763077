import React from "react";
import styled from "styled-components";

import RatioContainer from "../../../components/RatioContainer";
import px from "../../../helpers/px";

const Inner = styled.div``;

const Wrapper = styled.button`
  height: 100%;
  width: 100%;
  border: none;
  padding: ${px(17)} ${px(20)};

  background: #000 url(${({ background }) => background}) no-repeat center
    center / cover;

  display: flex;
  flex-direction: column-reverse;
  color: #fff;
  font-size: ${px(12)};
  line-height: ${px(14)};
  letter-spacing: 0.0165em;
  font-family: "RRRegular", sans-serif;
`;

const Title = styled.span``;

function Video({ video, onClick, isHero }) {
  return (
    <Inner isHero={isHero}>
      <RatioContainer>
        <Wrapper
          background={video?.poster?.filename}
          onClick={() => onClick(video)}
          id={video._uid}
        >
          {!isHero && <Title>{video.title}</Title>}
        </Wrapper>
      </RatioContainer>
    </Inner>
  );
}

export default Video;
