import React from "react";
import styled from "styled-components";

import px from "../../../helpers/px";
import {
  MARGIN_BOTTOM_IPAD,
  PADDING_VERTICAL_IPAD,
} from "../../../components/Header";
import {
  MARGIN_HORIZONTAL_IPAD,
  MARGIN_HORIZONTAL_PC,
} from "../../../styled/layout";
import { device } from "../../../constants/breakpoints";

import Copy from "../Copy";
import Video from "../Video";
import NextButton from "../NextButton";

const HERO_WIDTH_IPAD = 889 / 1366;
const RIGHT_SIDE_WIDTH_IPAD = (1366 - 889) / 1366;
const IPAD_MARGIN_TOP = px(116 - MARGIN_BOTTOM_IPAD - PADDING_VERTICAL_IPAD);

const HERO_WIDTH_PC = 1226 / 1920;
const RIGHT_SIDE_WIDTH_PC = (1920 - 1226 - MARGIN_HORIZONTAL_PC) / 1920;

const Wrapper = styled.div`
  margin-top: ${IPAD_MARGIN_TOP};
  width: ${HERO_WIDTH_IPAD * 100}%;
  ${({ isVisible }) => !isVisible && "display: none;"}

  @media ${device.pc} {
    margin-top: 0;
    width: ${HERO_WIDTH_PC * 100}%;
    padding-left: ${px(MARGIN_HORIZONTAL_PC)};
  }
`;

const VideosWrapper = styled.div`
  display: flex;
  padding-left: ${px(MARGIN_HORIZONTAL_IPAD)};

  justify-content: flex-end;
`;

const RightSide = styled.div`
  width: ${RIGHT_SIDE_WIDTH_IPAD * 100}%;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: ${IPAD_MARGIN_TOP};
  padding-right: ${px(MARGIN_HORIZONTAL_IPAD)};
  box-sizing: border-box;

  @media ${device.pc} {
    width: ${RIGHT_SIDE_WIDTH_PC * 100}%;
    margin-top: 0;
    padding-right: ${px(MARGIN_HORIZONTAL_PC)};
    display: flex;
    height: 100%;
    flex-direction: column;
  }
`;

const RightInner = styled.div``;

const Item = styled.div`
  flex: 0 0 ${px(411)};
`;

const Button = styled.button`
  display: block;
  background: transparent;
  border: none;
  padding: 0;
  position: absolute;
  top: -83px;
  left: 67px;

  @media ${device.pc} {
    bottom: 38px;
    top: initial;
    left: initial;
  }
`;

function TemplateLanding({
  videos,
  area,
  hasNextPage,
  onNextClick,
  isVisible,
  onSelectVideo,
  onHomeButtonClick,
}) {
  const top3 = videos.slice(0, 3);
  const remainingVids = videos.slice(3);

  return (
    <Wrapper isVisible={isVisible}>
      <Video video={videos[0]} onClick={onSelectVideo} isHero />
      <RightSide>
        <RightInner>
          {top3.map((v, i) => {
            return i === 0 ? (
              <Item key={v._uid}>
                <Copy title={v.title} body={v?.description}></Copy>
              </Item>
            ) : (
              <Item key={v._uid}>
                <Video video={v} onClick={onSelectVideo} />
              </Item>
            );
          })}
        </RightInner>
        {hasNextPage && (
          <NextButton copy={area.more_button} onClick={onNextClick} />
        )}
      </RightSide>
      <VideosWrapper>
        {remainingVids.map((v, i) => {
          return (
            <Item key={v._uid}>
              <Video video={v} onClick={onSelectVideo} />
            </Item>
          );
        })}
      </VideosWrapper>

      <Button onClick={onHomeButtonClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="33"
          viewBox="0 0 32 33"
        >
          <g id="Group_375" transform="translate(0.385 0.645)">
            <rect
              id="Rectangle_11"
              width="32"
              height="33"
              transform="translate(-0.385 -0.645)"
              fill="none"
            />
            <path
              id="Path_446"
              d="M-16899.508-1630.222v-11.15l7.213-6.19,7.184,6.19v10.19h-10.2"
              transform="translate(16908.014 1654.969)"
              fill="none"
              stroke="#000"
              strokeWidth="2"
            />
          </g>
        </svg>
      </Button>
    </Wrapper>
  );
}

export default TemplateLanding;
