import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react";
import styled from "styled-components";

import px from "../../helpers/px";
import { DataContext } from "../../contexts/data";
import CloseBtn from "../CloseBtn";

import playiconround from "./icons/playicon-round.svg";
import pauseiconround from "./icons/pauseicon-round.svg";
import pauseicon from "./icons/pauseicon.svg";
import playicon from "./icons/playicon.svg";
import restarticon from "./icons/restart.svg";
import {
  MARGIN_HORIZONTAL_IPAD,
  MARGIN_HORIZONTAL_PC,
} from "../../styled/layout";
import device from "../../constants/breakpoints";

const fade = "transition: opacity 300ms ease-in;";

const Wrapper = styled.div`
  background: #000;
  height: 100vh;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;

  @media ${device.pc} {
    *:focus {
      outline: none;
      box-shadow: inset 0 0 0 2px #6fdbc7 !important;
    }
  }
`;

const CloseBtnWrapper = styled.div`
  position: absolute;
  color: #fff;
  top: ${px(43)};
  left: ${px(MARGIN_HORIZONTAL_IPAD)};
  z-index: 1;

  @media ${device.pc} {
    left: ${px(MARGIN_HORIZONTAL_PC)};
  }
  opacity: ${({ isShowing }) => (isShowing ? "1" : "0")};
  ${fade}
`;

const PlayButton = styled.button`
  position: absolute;
  background: transparent;
  border: none;
  padding: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: ${({ isShowing }) => (isShowing ? "1" : "0")};
  ${fade}
  width: ${px(50)};

  img {
    width: 100%;
  }

  @media ${device.pc} {
    width: ${px(79)};
  }
`;

const Controls = styled.div`
  padding: ${px(37)} ${px(MARGIN_HORIZONTAL_IPAD)};
  display: flex;
  align-items: center;
  opacity: ${({ isShowing }) => (isShowing ? "1" : "0")};
  ${fade}
  position: absolute;
  width: 100%;
  bottom: 0;
  background: #000;
  box-sizing: border-box;

  @media ${device.pc} {
    padding: ${px(41)} ${px(MARGIN_HORIZONTAL_PC)};
  }
`;

const ControlBtn = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  margin-right: ${px(85)};
  width: ${px(17)};

  img {
    width: 100%;
  }
`;

const Track = styled.div`
  flex-grow: 1;
  overflow: hidden;
  height: ${px(2)};
  background-color: rgba(206, 206, 206, 0.24);
`;

const Rail = styled.div.attrs((props) => ({
  style: {
    transform: `translateX(${props.position}%)`,
  },
}))`
  width: 100%;
  background-color: #cecece;
  height: 100%;
`;

const Video = styled.video`
  width: 100%;
`;

const VideoWrapper = styled.div`
  width: 100%;
  position: relative;
  max-height: 100vh;
  overflow: hidden;
`;

const Time = styled.div`
  color: #fff;
  font-size: ${px(18)};
  line-height: ${px(22)};
  margin-left: ${px(28)};
  font-family: "RRRegular", sans-serif;

  @media ${device.pc} {
    font-size: ${px(19)};
    line-height: ${px(23)};
    margin-left: ${px(47)};
  }
`;

const Error = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;

  font-size: ${px(18)};
  line-height: ${px(22)};
  font-family: "RRRegular", sans-serif;

  @media ${device.pc} {
    font-size: ${px(19)};
    line-height: ${px(23)};
  }
`;

function VideoPlayer({ video, onClose }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isControlShowing, setIsControlShowing] = useState(true);
  const [railsPos, setRailsPos] = useState(-100);
  const [isLoading, setIsLoading] = useState(true);
  const [src, setSrc] = useState("");
  const { getVideoSrcFromDb, videoErrorMsg } = useContext(DataContext);
  const videoRef = useRef();
  const playButtonRef = useRef();
  const closeBtnRef = useRef();
  const controlTimer = useRef();

  const updateTimeline = () => {
    if (videoRef?.current) {
      const percent = videoRef.current.currentTime / videoRef.current.duration;
      const reverse = 1 - percent;
      setRailsPos(reverse * -100);
    }
  };

  const handleClose = useCallback(() => {
    setIsPlaying(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    videoRef.current.addEventListener("timeupdate", () => updateTimeline());
    return videoRef.current.removeEventListener("timeupdate", () =>
      updateTimeline()
    );
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(controlTimer.current);
    };
  }, []);

  useEffect(() => {
    videoRef.current.addEventListener("ended", () => handleClose());
    return videoRef.current.removeEventListener("ended", () => handleClose());
  }, [handleClose]);

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
      //closeBtnRef.current.focus();
    } else {
      videoRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    playButtonRef.current.focus();
  }, []);

  const restartVideo = useCallback(() => {
    videoRef.current.currentTime = 0;
    setRailsPos(-100);
  }, []);

  const getFormattedTime = useCallback(() => {
    const time = videoRef?.current?.duration - videoRef?.current?.currentTime;
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time - 60 * minutes);
    return `-${isNaN(minutes) ? `0` : minutes}:${
      seconds < 10 ? `0${seconds}` : isNaN(seconds) ? `0` : seconds
    }`;
  }, []);

  const restartControlTimer = useCallback(() => {
    clearTimeout(controlTimer.current);
    controlTimer.current = setTimeout(() => {
      setIsControlShowing(false);
    }, 3000);
  }, []);

  useEffect(() => {
    if (isControlShowing) {
      // restart timer if gone from false to true
      restartControlTimer();
    }
  }, [isControlShowing]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowDown") {
        setIsPlaying((isPlaying) => !isPlaying);
      }

      if (e.key === "ArrowRight") {
        restartVideo();
      }

      if (e.key === "Escape") {
        handleClose();
      }
    };
    document.addEventListener("keydown", handleKeyDown, false);

    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [handleClose, restartVideo]);

  useEffect(() => {
    async function fetchVideoUrl() {
      const {
        video: { id, filename },
      } = video;

      let href;
      try {
        href = await getVideoSrcFromDb(id);
      } catch (err) {
        if (navigator.onLine) {
          href = filename;
        }
      }

      setIsLoading(false);
      setSrc(href);
      videoRef.current.load();
      setIsPlaying(true);
    }
    fetchVideoUrl();
  }, [getVideoSrcFromDb, video]);

  return (
    <Wrapper>
      <CloseBtnWrapper isShowing={isControlShowing}>
        <CloseBtn
          onClick={handleClose}
          ref={closeBtnRef}
          disabled={isLoading || !isControlShowing}
          onFocus={restartControlTimer}
        />
      </CloseBtnWrapper>

      <VideoWrapper
        tabIndex="0"
        onFocus={() => {
          setIsControlShowing(true);
        }}
        onClick={() => {
          setIsControlShowing(true);
        }}
      >
        <Video ref={videoRef}>
          <source src={src} type="video/mp4" />
        </Video>
        <PlayButton
          onClick={() => setIsPlaying(!isPlaying)}
          ref={playButtonRef}
          isPlaying={isPlaying}
          disabled={isLoading || !isControlShowing}
          isShowing={isControlShowing}
          onFocus={restartControlTimer}
        >
          <img src={isPlaying ? pauseiconround : playiconround} alt="" />
        </PlayButton>

        {!isLoading && !src && <Error>{videoErrorMsg}</Error>}

        <Controls isShowing={isControlShowing}>
          <ControlBtn
            onClick={() => setIsPlaying(!isPlaying)}
            disabled={isLoading || !isControlShowing}
            onFocus={restartControlTimer}
          >
            <img src={isPlaying ? pauseicon : playicon} alt="" />
          </ControlBtn>
          <ControlBtn
            onClick={restartVideo}
            disabled={isLoading || !isControlShowing}
            onFocus={restartControlTimer}
          >
            <img src={restarticon} alt="" />
          </ControlBtn>
          <Track>
            <Rail position={railsPos} />
          </Track>
          <Time>{getFormattedTime()}</Time>
        </Controls>
      </VideoWrapper>
    </Wrapper>
  );
}

export default VideoPlayer;
