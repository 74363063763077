const Arrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.181 23.633">
    <g id="Group_30" transform="translate(0 0.707)">
      <g id="Group_14">
        <path
          id="Path_5"
          d="M0,15.723H15.7V0"
          transform="translate(3.548 11.101) rotate(-45)"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        />
        <g id="Group_13" transform="translate(0 11.13)">
          <line
            id="Line_1"
            data-name="Line 1"
            x2="14.657"
            transform="translate(10.823)"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          />
          <line
            id="Line_2"
            data-name="Line 2"
            x2="5.493"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Arrow;
