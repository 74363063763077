import React, { useContext } from "react";
import styled from "styled-components";

import { RouterContext, ROUTES } from "../../contexts/router";
import { DataContext } from "../../contexts/data";
import px from "../../helpers/px";
import { device } from "../../constants/breakpoints";
import { Wrapper, Inner } from "../../styled/layout";

const Cta = styled.button`
  background-color: #57208c;
  color: #fff;
  border: 2px solid currentColor;
  padding: ${px(14)} ${px(28)};
  display: flex;
  align-items: center;
  margin: auto;
  border-radius: ${px(50)};

  &:focus {
    background-color: #6d30a7;
  }

  ${(props) =>
    props.secondary &&
    `
    background-color: transparent;
    color: #57208c;

    &:focus {
      background-color: #57208c;
      color: #fff;
    }
  `}
`;

const Area = styled.section`
  margin-top: 2em;
  margin-bottom: 2em;

  ${(props) =>
    props.onDesktopOnly &&
    `
    @media ${device.ipadMax} {
      display: none;
    }
  `}
`;

const H2 = styled.h2`
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const Table = styled.table`
  width: 100%;
`;

const Td = styled.td`
  padding: 5px;
`;

const Th = styled.th`
  padding: 5px;
  text-align: left;
  width: 25%;
`;

const Img = styled.img`
  width: 50%;
`;

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  opacity: 0;
  z-index: -1;
`;

const Ctas = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const Error = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: red;
  margin-top: 1em;
`;

function Admin({ children }) {
  const {
    reloadDataFromCms,
    areas,
    downloadVideo,
    map,
    rows,
    deleteVideo,
    attractor,
    error,
  } = useContext(DataContext);
  const { onSetRoute, onSetDefaultArea, defaultArea } =
    useContext(RouterContext);

  return (
    <Wrapper>
      {children}
      <Inner>
        <Ctas>
          <Cta secondary onClick={reloadDataFromCms}>
            Reload data from CMS
          </Cta>
          <Cta secondary onClick={() => window.location.reload()}>
            Refresh page
          </Cta>
          <Cta onClick={() => onSetRoute(ROUTES.MAP)}>Go to map screen</Cta>
        </Ctas>
        {error && <Error>{error}</Error>}
        <ImgWrapper>
          <Img src={map.img.filename} />
        </ImgWrapper>

        <Area onDesktopOnly>
          <H2>Selected location</H2>
          {areas.map((a) => {
            return (
              <label key={a._uid}>
                <input
                  type="radio"
                  value=""
                  name="selected-location"
                  onChange={() => {
                    onSetDefaultArea(a);
                  }}
                  checked={defaultArea?._uid === a._uid}
                />{" "}
                {a.name}
              </label>
            );
          })}
          <button
            style={{ marginLeft: "10px" }}
            onClick={() => onSetDefaultArea(null)}
          >
            Clear selected location
          </button>
        </Area>

        <Area>
          <H2>Attractor</H2>
          <Table>
            <thead>
              <tr>
                <Th>Video name</Th>
                <Th>Downloaded?</Th>
                <Th>Download</Th>
                <Th>Delete</Th>
              </tr>
            </thead>
            <tbody>
              <tr key={attractor?.video.id}>
                <Td>{attractor?.video.title}</Td>
                <Td>{attractor?.video.downloaded ? "yes" : "no"}</Td>
                <Td>
                  <button
                    disabled={
                      attractor?.video.downloaded ||
                      attractor?.video.downloading
                    }
                    onClick={() => downloadVideo(attractor)}
                  >
                    {attractor?.video.downloading
                      ? "Downloading..."
                      : "Download"}
                  </button>
                </Td>
                <Td>
                  <button
                    disabled={
                      !attractor?.video.downloaded ||
                      attractor?.video.downloading
                    }
                    onClick={() => deleteVideo(attractor?.video.doc)}
                  >
                    Delete
                  </button>
                </Td>
              </tr>
            </tbody>
          </Table>
        </Area>

        {areas.map((a) => (
          <Area key={a._uid}>
            <H2>{a.name}</H2>
            <Table>
              <thead>
                <tr>
                  <Th>Video name</Th>
                  <Th>Downloaded?</Th>
                  <Th>Download</Th>
                  <Th>Delete</Th>
                </tr>
              </thead>
              <tbody>
                {a.videos.map((v) => {
                  return (
                    <tr key={v._uid}>
                      <Td>{v.title}</Td>
                      <Td>{v.downloaded ? "yes" : "no"}</Td>
                      <Td>
                        <button
                          disabled={v.downloaded || v.downloading}
                          onClick={() => downloadVideo(v, a)}
                        >
                          {v.downloading ? "Downloading..." : "Download"}
                        </button>
                      </Td>
                      <Td>
                        <button
                          disabled={!v.downloaded || v.downloading}
                          onClick={() => deleteVideo(v.doc, a)}
                        >
                          Delete
                        </button>
                      </Td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Area>
        ))}

        {rows.length > 0 && (
          <Area>
            <H2>
              These videos are no longer in used or have been updated, delete
              them to free up storage:
            </H2>
            <Table>
              <thead>
                <tr>
                  <Th>Video name</Th>
                  <Th>Delete</Th>
                </tr>
              </thead>
              <tbody>
                {rows.map((v) => {
                  return (
                    <tr key={v.id}>
                      <Td>{v.doc?.title}</Td>
                      <Td>
                        <button
                          disabled={v.downloaded || v.downloading}
                          onClick={() => deleteVideo(v.doc)}
                        >
                          {v.downloading ? "Deleting..." : "Delete"}
                        </button>
                      </Td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Area>
        )}
      </Inner>
    </Wrapper>
  );
}

export default Admin;
