import React from "react";
import styled from "styled-components";
import px from "../../../helpers/px";

import Arrow from "./Arrow";

const Next = styled.button`
  padding: ${px(0)};
  border: none;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: flex-end;
  background: transparent;
  font-size: ${px(18)};
  line-height: ${px(90)};
  color: #000;
  font-family: "RRRegular", sans-serif;
  letter-spacing: 0.09em;
`;

const IconWrapper = styled.div`
  width: ${px(13)};
  margin-left: ${px(23)};
`;

function NextButton({ copy, onClick }) {
  return (
    <Next onClick={onClick}>
      {copy}
      <IconWrapper>
        <Arrow />
      </IconWrapper>
    </Next>
  );
}

export default NextButton;
