import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import styled from "styled-components";

import px from "../../helpers/px";
import { device } from "../../constants/breakpoints";
import { RouterContext, ROUTES } from "../../contexts/router";
import Logo from "./Logo";

export const PADDING_VERTICAL_PC = 35;
export const PADDING_VERTICAL_IPAD = 41;
export const MARGIN_BOTTOM_PC = 0;
export const MARGIN_BOTTOM_IPAD = 2;
export const LOGO_HEIGHT_PC = 49;
export const LOGO_HEIGHT_IPAD = 43;

const Wrapper = styled.header`
  padding: 0 ${px(140)};
  color: ${(props) => (props.isLight ? "#fff" : "#000")};
  margin-bottom: ${px(MARGIN_BOTTOM_IPAD)};

  @media ${device.pc} {
    margin-bottom: ${px(MARGIN_BOTTOM_PC)};
  }
`;

const Inner = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding: ${px(PADDING_VERTICAL_IPAD)} 0;

  @media ${device.pc} {
    padding: ${px(PADDING_VERTICAL_PC)} 0;
  }
`;

const LogoWrapper = styled.button`
  width: ${px(35)};
  height: ${px(LOGO_HEIGHT_IPAD)};
  border: none;
  padding: 0;
  background: none;
  color: inherit;

  @media ${device.pc} {
    width: ${px(39)};
    height: ${px(LOGO_HEIGHT_PC)};
  }
`;

function Header({ isLight, hasBorder }) {
  const [clicks, setClicks] = useState(0);

  const countDownRef = useRef(null);
  const clickCountRef = useRef(clicks);
  clickCountRef.current = clicks;

  const { onSetRoute, route } = useContext(RouterContext);

  const handleNavigation = useCallback(() => {
    if (route === ROUTES.MAP) {
      const shouldNavigate = () => {
        if (clickCountRef.current > 4) {
          onSetRoute(ROUTES.ADMIN);
        }
        setClicks(0);
      };

      if (!countDownRef.current) {
        countDownRef.current = setTimeout(() => {
          shouldNavigate();
          countDownRef.current = null;
        }, 3000);
      }
      setClicks((c) => c + 1);
    } else {
      onSetRoute(ROUTES.MAP);
    }
  }, [onSetRoute, route])

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if(e.key === 'ArrowLeft') {
        handleNavigation()
      }
      
    }, false);
  }, [handleNavigation])

  useEffect(() => {
    return () => {
      clearTimeout(countDownRef.current);
    };
  }, []);

  return (
    <Wrapper isLight={isLight}>
      <Inner hasBorder={hasBorder}>
        <LogoWrapper onClick={handleNavigation}>
          <Logo />
        </LogoWrapper>
      </Inner>
    </Wrapper>
  );
}

export default Header;
