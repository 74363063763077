import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
//import LocalServiceWorkerRegister from "./sw-register";
//import reportWebVitals from "./reportWebVitals";

import DataProvider from "./contexts/data";
import RouterProvider from "./contexts/router";

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider>
      <DataProvider>
        <App />
      </DataProvider>
    </RouterProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onSuccess: () =>
    alert(
      "App has been saved for offline use. Please reload the page to cache content."
    ),
});

//LocalServiceWorkerRegister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
