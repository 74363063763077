import React, { useContext, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { DataContext } from "./contexts/data";
import { RouterContext, ROUTES } from "./contexts/router";
import { ROUTES_CONFIG } from "./constants/config";
import "./App.css";

import Loading from "./screens/Loading";
import Header from "./components/Header";
import device from "./constants/breakpoints";

const Main = styled.main`
  height: 100vh;
  display: flex;
  flex-direction: column;

  @media ${device.ipadMax} {
    *:focus {
      outline: none;
    }
  }

  @media ${device.pc} {
    *:focus {
      outline: none;
      box-shadow: inset 0 0 0 2px #000;
    }
  }
`;

function App() {
  const data = useContext(DataContext);
  const { route, onSetRoute, videoIsPlaying } = useContext(RouterContext);
  const timerRef = useRef(null);
  const { isLoading } = data;

  const { header, Component } = ROUTES_CONFIG[route];

  const startTimer = useCallback(() => {
    const doInactive = () => {
      if (!videoIsPlaying) {
        if (!route !== ROUTES.LANDING || route !== ROUTES.ADMIN) {
          onSetRoute(ROUTES.LANDING);
        }
      }

      startTimer();
    };

    const timeoutInMiliseconds = 60000 * 5;

    timerRef.current = window.setTimeout(
      () => doInactive(),
      timeoutInMiliseconds
    );
  }, [onSetRoute, route, videoIsPlaying]);

  const resetTimer = useCallback(() => {
    clearTimeout(timerRef.current);
    startTimer();
  }, [startTimer]);

  useEffect(() => {
    document.addEventListener("mousemove", () => resetTimer(), false);
    document.addEventListener("mousedown", () => resetTimer(), false);
    document.addEventListener("keydown", () => resetTimer(), false);
    document.addEventListener("touchmove", () => resetTimer(), false);

    startTimer();

    return () => {
      window.clearTimeout(timerRef.current);
    };
  }, [startTimer, resetTimer]);

  return (
    <Main>
      {isLoading ? (
        <>
          <Header {...header} />
          <Loading />
        </>
      ) : (
        <Component>
          <Header {...header} />
        </Component>
      )}
    </Main>
  );
}

export default App;
