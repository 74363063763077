const breakpoints = {
  pc: 1366,
};

const device = {
  ipadMax: `(max-width: ${breakpoints.pc}px)`,
  pc: `(min-width: ${breakpoints.pc + 1}px)`,
};

export { breakpoints, device };
export default device;
