import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";

import device from "../../constants/breakpoints";
import { ROUTES, RouterContext } from "../../contexts/router";
import { Wrapper } from "../../styled/layout";
import VideoPlayer from "../../components/VideoPlayer";

import TemplateLanding from "./TemplateLanding";
import TemplateOther from "./TemplateOther";

const VIDEOS_ON_FIRST_PAGE = 5;
const VIDEOS_ON_OTHER_PAGE = 9;

export const Inner = styled.div`
  flex: 1;
  position: relative;
  ${(props) => (props.hidden ? `display: none;` : "")}
`;

function Area({ children }) {
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [video, setVideo] = useState(null);
  const { area, setVideoPlaying, onSetRoute } = useContext(RouterContext);

  useEffect(() => {
    const { videos } = area;
    const videosToSlice = [...videos];

    const videoPages = [];

    while (videosToSlice.length > 0) {
      const nrOfVideosPerPage =
        videoPages.length === 0 ? VIDEOS_ON_FIRST_PAGE : VIDEOS_ON_OTHER_PAGE;
      const videosForPage = videosToSlice.splice(0, nrOfVideosPerPage);
      videoPages.push(videosForPage);
    }

    setPages(videoPages);
  }, [area]);

  const handleVideoClose = () => {
    const btn = document.getElementById(`${video._uid}`);
    btn?.focus();
    document
      .querySelector('meta[name="apple-mobile-web-app-status-bar-style"]')
      .setAttribute("content", "black-translucent");
    setVideo(null);
    setVideoPlaying(false);
  };

  const onSetVideo = (video) => {
    document
      .querySelector('meta[name="apple-mobile-web-app-status-bar-style"]')
      .setAttribute("content", "black");

    setVideo(video);
    setVideoPlaying(true);
  };

  return (
    <Wrapper>
      {!video && children}

      <Inner hidden={video}>
        {pages.length > 0 &&
          pages.map((p, i) => {
            return i === 0 ? (
              <TemplateLanding
                videos={p}
                key={p[0]._uid}
                area={area}
                hasNextPage={pages.length > 1}
                onNextClick={() => {
                  setPage(1);
                }}
                isVisible={page === i}
                onSelectVideo={onSetVideo}
                onHomeButtonClick={() => {
                  onSetRoute(ROUTES.MAP);
                }}
              />
            ) : (
              <TemplateOther
                videos={p}
                key={p[0]._uid}
                area={area}
                hasNextPage={pages.length > i + 1}
                onNextClick={() => {
                  setPage(i + 1);
                }}
                isVisible={page === i}
                onCloseClick={() => setPage(0)}
                onSelectVideo={onSetVideo}
              />
            );
          })}
      </Inner>

      {video && <VideoPlayer video={video} onClose={handleVideoClose} />}
    </Wrapper>
  );
}

export default Area;
