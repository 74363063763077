import { forwardRef } from "react";
import styled from "styled-components";
import device from "../../constants/breakpoints";
import px from "../../helpers/px";

const Svg = styled.svg`
  width: ${px(15)};
  fill: currentColor;

  @media ${device.pc} {
    width: ${px(24)};
  }
`;

const Btn = styled.button`
  border: none;
  background: transparent;
  padding: ${px(12)};
  z-index: 1;
  color: inherit;
`;

const CloseBtn = forwardRef(({ onClick, isDisabled }, ref) => (
  <Btn ref={ref} onClick={onClick} disabled={isDisabled}>
    <Svg viewBox="0 0 33.015 33.149">
      <g id="Group_5" data-name="Group 5" transform="translate(0 0)">
        <g id="Group_3" data-name="Group 3">
          <rect
            id="Rectangle_5"
            data-name="Rectangle 5"
            width="41.354"
            height="5.336"
            transform="translate(3.773 0.134) rotate(45)"
          />
          <rect
            id="Rectangle_6"
            data-name="Rectangle 6"
            width="22.678"
            height="5.336"
            transform="translate(19.809 17.113) rotate(135)"
          />
          <rect
            id="Rectangle_7"
            data-name="Rectangle 7"
            width="13.34"
            height="5.336"
            transform="translate(33.014 3.773) rotate(135)"
          />
        </g>
      </g>
    </Svg>
  </Btn>
));

export default CloseBtn;
