import React, { useContext, useCallback } from "react";
import styled from "styled-components";
import { DataContext } from "../../contexts/data";
import { RouterContext } from "../../contexts/router";
import { Wrapper, Inner } from "../../styled/layout";
import px from "../../helpers/px";
import { device } from "../../constants/breakpoints";

const Title = styled.h1`
  font-size: ${px(34)};
  line-height: ${px(51)};
  letter-spacing: 0.09em;
  text-align: center;
  margin-top: ${px(30)};
  margin-bottom: ${px(46)};
  color: #000;

  @media ${device.pc} {
    font-size: ${px(30)};
    line-height: ${px(46)};
    margin-top: ${px(53)};
  }
`;

const Areas = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Area = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 ${100 / 3}%;
  justify-content: center;
`;

const AreaBtn = styled.button`
  padding: ${px(16)} ${px(16)};
  border: none;
  background: transparent;
  font-family: "RRRegular", sans-serif;
  color: #000;
  margin: 0;
  text-transform: uppercase;
  font-size: ${px(12)};
  line-height: 1;
  letter-spacing: 0.009em;

  @media ${device.pc} {
    font-size: ${px(18)};
    line-height: 1;
    letter-spacing: 0.009em;
  }
`;

function Map({ children }) {
  const { onSetArea, defaultArea } = useContext(RouterContext);
  const {
    areas,
    map: { img, title },
  } = useContext(DataContext);

  const onAreaClick = useCallback(
    (area) => {
      onSetArea(area);
    },
    [onSetArea]
  );

  const mapImage = defaultArea?.still_image || img.filename;

  return (
    <Wrapper bg={mapImage}>
      {children}
      <Title>{title}</Title>
      <Inner>
        <Areas>
          {areas.map((a) => (
            <Area key={a._uid}>
              <AreaBtn onClick={() => onAreaClick(a)}>{a.name}</AreaBtn>
            </Area>
          ))}
        </Areas>
      </Inner>
    </Wrapper>
  );
}

export default Map;
