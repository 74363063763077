import React from "react";
import styled from "styled-components";

import {
  Inner,
  MARGIN_HORIZONTAL_IPAD,
  MARGIN_HORIZONTAL_PC,
} from "../../../styled/layout";
import px from "../../../helpers/px";
import CloseBtn from "../../../components/CloseBtn";

import Video from "../Video";
import NextButton from "../NextButton";

import {
  PADDING_VERTICAL_PC,
  MARGIN_BOTTOM_PC,
  LOGO_HEIGHT_PC,
  MARGIN_BOTTOM_IPAD,
  PADDING_VERTICAL_IPAD,
  LOGO_HEIGHT_IPAD,
} from "../../../components/Header";
import device from "../../../constants/breakpoints";

const VideosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.div`
  flex: 0 0 calc(100% / 3);

  @media ${device.pc} {
    flex: 0 0 calc(100% / 4);
  }
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
`;

const H1 = styled.h1`
  font-size: ${px(34)};
  line-height: ${px(51)};
  text-align: center;
  margin-bottom: ${px(39)};

  @media ${device.pc} {
    font-size: ${px(30)};
    line-height: ${px(46)};
    margin-bottom: ${px(82)};
  }
`;

const CloseBtnWrapper = styled.div`
  position: absolute;
  top: -${px(MARGIN_BOTTOM_IPAD + PADDING_VERTICAL_IPAD + LOGO_HEIGHT_IPAD / 2)};
  transform: translateY(-50%);
  margin-left: ${px(MARGIN_HORIZONTAL_IPAD)};

  @media ${device.pc} {
    margin-left: ${px(MARGIN_HORIZONTAL_PC)};
    top: -${px(MARGIN_BOTTOM_PC + PADDING_VERTICAL_PC + LOGO_HEIGHT_PC / 2)};
  }
`;

const Template = styled.div`
  ${({ isVisible }) => !isVisible && "display: none;"}
`;

function TemplateOther({
  videos,
  area,
  hasNextPage,
  onNextClick,
  onCloseClick,
  isVisible,
  onSelectVideo,
}) {
  return (
    <Template isVisible={isVisible}>
      <CloseBtnWrapper>
        <CloseBtn onClick={onCloseClick} />
      </CloseBtnWrapper>
      <Wrapper>
        <Inner>
          <H1>{area.more_title}</H1>
          <VideosWrapper>
            {videos.map((v) => {
              return (
                <Item key={v._uid}>
                  <Video video={v} onClick={onSelectVideo} />
                </Item>
              );
            })}

            {hasNextPage && (
              <Item>
                <NextButton copy={area.more_button} onClick={onNextClick} />
              </Item>
            )}
          </VideosWrapper>
        </Inner>
      </Wrapper>
    </Template>
  );
}

export default TemplateOther;
